<template>
  <v-container>
    <h2 class="mb-4">Reactions of Copper - Results</h2>

    <p class="mb-3">
      Fill in the table below using the data you collected throughout the experiment and any
      required calculations.
    </p>

    <v-simple-table style="max-width: 450px" class="ml-n4">
      <thead>
        <tr>
          <td>
            <stemble-latex content="$\text{Property}$" />
          </td>
          <td style="text-align: center">
            <stemble-latex content="$\text{Value}$" />
          </td>
        </tr>
        <tr>
          <td>a) Initial mass of Cu (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massCuInit"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>b) Final mass of Cu (g)</td>
          <td>
            <calculation-input
              v-model="inputs.massCuFinal"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>

        <tr>
          <td>c) Percent recovery (%)</td>
          <td>
            <calculation-input
              v-model="inputs.pctYield"
              class="centered-input my-1"
              :disabled="!allowEditing"
            />
          </td>
        </tr>
      </thead>
    </v-simple-table>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'McMaster1A03Lab2Task2',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        massCuInit: null,
        massCuFinal: null,
        pctYield: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
